import { inputMasksInit } from "./inputMasks.js"

const onDiscountRadioChanged = (e) => {
  const parentDiv = e.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode
  const percentDiscountInput = parentDiv.querySelector('.percent_discount_input')
  const dollarDiscountInput = parentDiv.querySelector('.dollar_discount_input')

  switch(e.target.value) {
    case 'percent':
      if (e.target.checked) {
        percentDiscountInput.classList.remove('hidden')
        dollarDiscountInput.classList.add('hidden')
        dollarDiscountInput.querySelector('input').value = 0.0
      }
      break;
    case 'dollar':
      if (e.target.checked) {
        percentDiscountInput.classList.add('hidden')
        dollarDiscountInput.classList.remove('hidden')
        percentDiscountInput.querySelector('input').value = 0.0
      }
      break
    case 'none':
      if (e.target.checked) {
        percentDiscountInput.classList.add('hidden')
        dollarDiscountInput.classList.add('hidden')
        dollarDiscountInput.querySelector('input').value = 0.0
        percentDiscountInput.querySelector('input').value = 0.0
      }
      break;
    default:
      percentDiscountInput.classList.add('hidden')
      dollarDiscountInput.classList.add('hidden')
      dollarDiscountInput.querySelector('input').value = 0.0
      percentDiscountInput.querySelector('input').value = 0.0
      break;
  }
}

export const discountRadioInit = () => {
  inputMasksInit()
  const radios = document.getElementsByClassName('discount-type-radio')

  if (radios) {
    Array.from(radios).forEach((radio) => {
      radio.addEventListener('change', onDiscountRadioChanged)

      const parentDiv = radio.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode
      const percentDiscountInput = parentDiv.querySelector('.percent_discount_input')
      const dollarDiscountInput = parentDiv.querySelector('.dollar_discount_input')

      switch(radio.value) {
        case 'percent':
          if (radio.checked) {
            percentDiscountInput.classList.remove('hidden')
            dollarDiscountInput.classList.add('hidden')
            dollarDiscountInput.querySelector('input').value = 0.0
          }
          break;
        case 'dollar':
          if (radio.checked) {
            percentDiscountInput.classList.add('hidden')
            dollarDiscountInput.classList.remove('hidden')
            percentDiscountInput.querySelector('input').value = 0.0
          }
          break
        case 'none':
          if (radio.checked) {
            percentDiscountInput.classList.add('hidden')
            dollarDiscountInput.classList.add('hidden')
            dollarDiscountInput.querySelector('input').value = 0.0
            percentDiscountInput.querySelector('input').value = 0.0
          }
          break;
        default:
          percentDiscountInput.classList.add('hidden')
          dollarDiscountInput.classList.add('hidden')
          dollarDiscountInput.querySelector('input').value = 0.0
          percentDiscountInput.querySelector('input').value = 0.0
          break;
      }
    })
  }
}

export const discountRadioDestroy = () => {
  const radios = document.getElementsByClassName('discount-type-radio')

  if (radios) {
    Array.from(radios).forEach((radio) => {
      radio.removeEventListener('change', onDiscountRadioChanged)
     })
  }
}