// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `bin/rails generate channel` command.

import { createConsumer } from "@rails/actioncable"

// const prepareConsumer = () => {
//   const protocol_string = process.env.NODE_ENV === 'development' ? 'ws://' : 'wss://'
//   console.log(protocol_string + window.location.host + '/cable')
//   return createConsumer(protocol_string + window.location.host + '/cable');
// };

export default createConsumer();
