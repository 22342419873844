import consumer from "./consumer";

consumer.subscriptions.create("UploadProgressChannel", {
  connected: () => {},
  received: (data) => {
    if ("path" in data) {
      window.location.pathname = data.path;
    }
    if ("csv_file_name" in data) {
      var csv = new Blob([data["csv_content"]], { type: "text/csv" });
      var csv_download_link = document.createElement("a");
      csv_download_link.href = window.URL.createObjectURL(csv);
      csv_download_link.download = data["csv_file_name"];
      csv_download_link.click();
    } else if ("message" in data) {
      if ($("div.flash.flash_notice").length) {
        $("div.flash.flash_notice").text(data["message"]);
      } else {
        $("div.flashes").append(
          `<div class="flash flash_notice">${data["message"]}</div>`
        );
      }
    } else if ("error" in data) {
      if ($("div.flash.flash_alert").length) {
        $("div.flash.flash_notice").hide();
        $("div.flash.flash_alert").text(data["error"]);
      } else {
        $("div.flash.flash_notice").hide();

        $("div.flashes").append(
          `<div class="flash flash_alert">${data["error"]}</div>`
        );
      }
    }
  },
});
