import IMask from 'imask'

const masks = []

export const inputMasksInit = () => {
  // Mask Currency Inputs
  document.querySelectorAll('.mask-currency-input').forEach((currencyInput) => {
    const mask = IMask(
      currencyInput,
      {
        mask: '$num',
        blocks: {
          num: {
            mask: Number,
            thousandsSeparator: ',',
            padFractionalZeros: true,
            radix: '.',
            scale: 2,
          },
        },
      },
    )

  masks.push(mask)
  })

  // Mask Percentage Inputs
  document.querySelectorAll('.mask-percentage-input').forEach((percentageInput) => {
    const mask = IMask(
      percentageInput,
      {
        mask: 'num%',
        lazy: false,
        blocks: {
          num: {
            mask: Number,
            scale: 3,
            min: 0,
            max: 100,
            radix: '.',
          },
        },
      }
    )

    masks.push(mask)
  })
}

export const inputMasksDestroy = () => {
  masks.forEach((mask) => {
    mask.destroy()
  })
}
