import "jquery";
import { discountRadioInit } from "../src/discountRadio.js"
import { inputMasksInit } from "../src/inputMasks.js"

global.$ = require("jquery");
window.discountRadioInit = discountRadioInit
window.inputMasksInit = inputMasksInit

import "../stylesheets/active_admin";
import '../src/directUpload.js'
import "@activeadmin/activeadmin";
import "activeadmin_addons";
import "@fortawesome/fontawesome-free/css/all.css";
import "arctic_admin";
import "jstree";
import "imask";
import "channels";
import MicroModal from 'micromodal';
import * as ActiveStorage from "@rails/activestorage";

ActiveStorage.start();
MicroModal.init();